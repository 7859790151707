.hero-contact {
  @include media-breakpoint-only(sm) {
    h1 {
      padding-top: 3rem;
    }
  }

  @include media-breakpoint-down(sm) {
    h1 {
      padding-top: 2rem;
      font-size: 1.3rem !important;
    }

    &-img {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      width: calc(100% + 1.5rem);
      position: relative;

      &::after {
        content: "";
        inset: 0;
        z-index: 0;
        position: absolute;
        background: $gradient;
        opacity: 30%;
      }
    }
  }

  &-hints {
    counter-reset: hint;

    .glightbox {
      position: relative;
      &::before {
        counter-increment: hint;
        content: counter(hint);
        position: absolute;
        left: 0.75rem;
        top: 0.5rem;
        background-color: $info;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 500px;
        color: #fff;
        -webkit-text-fill-color: initial;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.contact-form-decoration {
  position: absolute;
  z-index: -1;
  right: 75%;
  top: 5rem;

  @include media-breakpoint-up(xxl) {
    right: 90%;
  }
}

.image-numer {
  counter-reset: hint;
  .col-md-3 {
    position: relative;
    &:before {
      counter-increment: hint;
      content: counter(hint);
      width: 1.5rem;
      height: 1.5rem;
      color: #fff;
      -webkit-text-fill-color: initial;
      background-color: #237ff8;
      border-radius: 500px;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      display: flex;
      position: absolute;
      top: .5rem;
      left: .75rem;
    }
  }
}
