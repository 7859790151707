.homemap {
  min-height: 350px;

  @include media-breakpoint-down(sm) {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    width: calc(100% + 1.5rem);
    max-width: calc(100% + 1.5rem);
  }

  &-decoration {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      bottom: 2.5rem;
      right: -3rem;
      width: 255.675px;
      height: 282.343px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 282.343 255.675'%3E%3Cg fill='none' stroke='%23e5e5e5' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='3'%3E%3Cpath d='m81.351 20.038-20.063-3.614a59.864 59.864 0 0 0-8.427-.8h0c-17.054 0-33.1 8.627-41.531 23.474-5.617 9.626-9.83 22.27-9.83 39.126h0a83.2 83.2 0 0 0 10.834 40.929l11.035 19.06a69.6 69.6 0 0 1 9.231 29.49 166.489 166.489 0 0 0 21.468 68.616l7.223 12.64a10.288 10.288 0 0 0 9.028 5.216h0a10.361 10.361 0 0 0 10.433-10.433v-20.063a84.73 84.73 0 0 1 5.417-29.894l2.809-7.825a11.048 11.048 0 0 1 10.433-7.223h0a11.206 11.206 0 0 1 10.633 7.624l2.809 8.427a88.336 88.336 0 0 1 4.615 27.888v21.066a10.361 10.361 0 0 0 10.432 10.433h0a10.288 10.288 0 0 0 9.028-5.216l7.223-12.64a166.489 166.489 0 0 0 21.468-68.616 72.523 72.523 0 0 1 9.229-29.493m46.344-30.897 3.009 2.608 17.656 15.649 13.643 12.043 20.264 17.856a14.441 14.441 0 0 1 2.006 19.863c-.4.4-.6.8-1 1.2l-.2.2a14.616 14.616 0 0 1-20.063.6l-20.063-17.656-13.647-12.036-17.656-15.649-3.21-2.809 3.21-3.611 12.439-14.044Z'/%3E%3Cpath d='M211.561 72.002a62.713 62.713 0 0 0-1-22.069 61.937 61.937 0 0 0-5.016-14.044 59.269 59.269 0 0 0-14.448-18.86A59.945 59.945 0 0 0 169.83 4.79a64.1 64.1 0 0 0-33.706-1.805 62.332 62.332 0 0 0-47.55 49.355c-.4 2.207-.6 4.213-.8 6.42a62.443 62.443 0 0 0 20.863 51.763 62.884 62.884 0 0 0 47.55 15.248 61.35 61.35 0 0 0 40.528-20.665 64.475 64.475 0 0 0 14.846-33.104Zm-15.248 33.706 15.047 13.242'/%3E%3Cpath d='m118.669 44.114 20.264-6.019 2.207 25.881 29.693 8.828-7.223 22.27M63.295 40.502c-10.232-2.207-21.066.8-27.687 8.828-.4.6-1 1.2-1.4 1.806a29.911 29.911 0 0 0-5.618 18.859'/%3E%3C/g%3E%3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;

      @include media-breakpoint-up(lg) {
        right: -7.5rem;
      }
    }
  }
}
