// Our offer
#oferta {
  scroll-margin: 50px;

  @include media-breakpoint-up(lg) {
    scroll-margin: 150px;
  }

  img {
    aspect-ratio: 960/1280;
    object-fit: cover;

    @include media-breakpoint-down(sm) {
      aspect-ratio: 960/1150;
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      max-width: calc(100% + 1.5rem);
    }
  }
}

.book-online {
  background-color: rgba(#1d1257, 90%);

  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: -62.5px;
    top: -35px;
    width: 125px;
    height: 237px;
    background-image: url("data-url:/images/implant.png");
    z-index: -1;
  }
}

// About implants
.about-implants {
  &-img {
    @include media-breakpoint-down(sm) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      max-width: calc(100% + 1.5rem);
      width: calc(100% + 1.5rem);
    }

    @include media-breakpoint-up(lg) {
      max-width: calc(100% - 312px);
    }
  }

  &-content {
    align-self: center;
    @include media-breakpoint-up(lg) {
      margin-left: -150px;
    }
  }

  &-container {
    @include media-breakpoint-up(lg) {
      position: absolute;
      inset: 0;
      top: 50%;
      transform: translateY(-50%);
      height: fit-content;
    }
  }

  &-bg {
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
      object-fit: cover;
      max-width: min(100%, 1920px);
      margin: auto;
      z-index: -1;
    }
  }
}
