.page-content ul:not(.pagination) {
  list-style: none;
  padding-left: 0;
  margin-bottom: 2rem;

  li {
    margin: 0.25rem 0;
    position: relative;
    padding-left: 25px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0.7rem;
      width: 12px;
      height: 2px;
      background: $gradient;
    }
  }
}
