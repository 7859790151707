.gallery {
  &-hero-decoration {
    display: none;

    @include media-breakpoint-up(sm) {
      display: block;
      position: absolute;
      max-width: 500px;
      top: calc(100% - 13rem);
      right: -6rem;
      z-index: -1;
    }

    @include media-breakpoint-up(xl) {
      right: 0;
    }
  }

  .glightbox {
    position: relative;
    aspect-ratio: 16/11;
    overflow: hidden;
    @extend .bg-overlay;

    img {
      transition: transform 0.3s;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &-desktop-container {
    display: contents;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
}
.my-gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 16/11;
 
}
.my-gallery a {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: block;
  &:hover img, &:hover:after {
    transform: scale(1.05);
  }
  img {
    transition: transform 0.3s;
  }
}
.my-gallery a[data-fslightbox]:after {
  content: "";
  z-index: 0;
  opacity: 0.25;
  background: linear-gradient(90deg, #6640d1, #36a0e6);
  inset: 0;
  position: absolute;
}
.my-gallery {
  .col-image-50 a[data-fslightbox] {
    width: 50%;
    height: 50%;
  }

  .col-image a {
    height: 50% !important;
  }
}
