.service-hero {
  position: relative;

  &-bg {
    @extend .img-cover;
    z-index: -1;
  }

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: $gradient;
    opacity: 20%;
    z-index: 0;
  }

  &-list {
    background-color: $dark;
    counter-reset: services;

    @include media-breakpoint-up(md) {
      margin-right: 4rem;
      margin-bottom: -3rem;
    }

    @include media-breakpoint-down(md) {
      margin-left: -0.75rem;
      margin-right: -0.75rem;
      width: calc(100% + 1.5rem);

      li + li {
        border-top: 0.1px solid $dark;
      }

      li:not(.active):after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0.75rem;
        transform: translateY(-50%);
        background-image: var(--arrow-right);
        width: 10.881px;
        height: 18.083px;
        z-index: 10;
      }
    }
  }

  &-card {
    position: relative;
    display: block;
    color: $white;
    padding: 1rem 2rem;
    font-size: 1rem;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    z-index: 1;

    &:before {
      counter-increment: services;
      content: counter(services);
      position: absolute;
      left: 0.75rem;
      font-weight: 500;
      color: #fff;
    }

    &:not(.active) {
      &:after {
        content: "";
        position: absolute;
        inset: 0;
        background: $gradient;
        transform: rotate(180deg);
        z-index: -1;
      }

      &:hover {
        background: initial !important;
        background-clip: initial !important;
        -webkit-text-fill-color: initial !important;
        color: #fff !important;

        &:after {
          background-color: rgba(#237ff8, 50%);
        }
      }
    }

    &.active {
      color: $secondary;
    }

    &-img {
      display: none;
    }

    @include media-breakpoint-up(md) {
      width: 14rem;

      &:not(.active) {
        padding-top: 160px;

        &:after {
          background: rgba($dark, 50%);
        }
      }

      &-img {
        display: block;
        position: absolute;
        inset: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
    }
  }
}

.service-content {
  .divider-box {
    margin-bottom: 1.75rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
      opacity: 0;
    }

    @include media-breakpoint-up(lg) {
      opacity: 1;
      margin-bottom: 3.5rem;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-left: 18.75rem;
  }
}
