.navbar {
  position: sticky;
  top: 0;
  z-index: 1040;
  background-color: $white;
  box-shadow: 0 0 10px rgba($dark, 20%);
}

.logo {
  max-width: 150px;
  height:65px;
  @include media-breakpoint-up(md) {
    max-width: 180px;
  }
}

.nav-main {
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
  gap: 2rem;
  text-align: center;

  @include media-breakpoint-down(lg) {
    margin-top: 45px;
    margin-bottom: 70px;

    li + li {
      position: relative;
      margin-top: 10px;

      &:before {
        content: "";
        display: block;
        margin: 0 auto 10px auto;
        width: 2px;
        height: 15px;
        background: $gradient;
      }
    }

    a {
      color: white;
      font-size: 1.15rem;

      &:hover {
        background: $gradient;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    margin-left: auto;
  }

  @include media-breakpoint-up(xl) {
    gap: 3rem;
  }
}

#navbarMenu {
  @include media-breakpoint-up(lg) {
    display: contents;
  }
}

.navbar-toggle {
  border: none;

  &-item {
    position: relative;
    font-size: 14px;
    line-height: 2;
    color: $primary;
    transition: all 0.25s;

    &:before,
    &:after {
      content: "";
      width: 120%;
      margin-left: -10%;
      display: block;
      height: 3px;
      background-color: $primary;
    }
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.lng-btn {
  @include media-breakpoint-down(lg) {
    display: none;
  }

  padding: 2px;
  margin: 5px;
  color: $text-color;

  &.active {
    color: $info;
  }
}
