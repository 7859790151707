.c-accordion {
  &-btn {
    @extend .btn;
    background-color: $light;
    border-radius: 0;
    display: block;
    width: 100%;
    text-align: start;
    padding: 1rem 0.75rem;
    padding-right: 2rem;
    color: $text-muted;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
      width: 1.25rem;
      height: 0.25rem;
      background-color: $secondary;
      transition: 0.3s transform;
    }

    &.collapsed::after {
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &-content {
    padding: 0.75rem;
    background-color: $light;
    color: $text-muted;
  }
}
