.hero {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  &::after {
    content: "";
    inset: 0;
    z-index: 0;
    position: absolute;
    background: linear-gradient(to bottom, #6640d1, #36a0e6);
    opacity: 20%;
  }

  &-inner {
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(lg) {
      background: linear-gradient(to bottom, #6640d1cc, #36a0e6cc);
      position: absolute;
      inset: 1.5rem;
      width: calc(50% - 3.5rem);
      max-width: 100%;
      margin-left: 0;
      display: flex;
      justify-content: end;
      align-items: center;
    }

    @include media-breakpoint-up(xl) {
      inset: 3rem;
    }

    @include media-breakpoint-up(xxl) {
      inset: 3.5rem;
    }
  }

  &-content {
    @include media-breakpoint-up(lg) {
      margin-right: -140px;
      display: inline-block;
      max-width: 610px;

      p {
        max-width: 460px;
      }
    }

    @include media-breakpoint-up(xxl) {
      margin-right: -230px;

      .fs-1 {
        font-size: 4.5rem !important;
      }
    }
  }

  &-academic-title {
    line-height: 1.25;
    color: $secondary;

    @include media-breakpoint-up(lg) {
      color: $white;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.25rem !important;
    }
  }

  &-name {
    color: $white;

    @include media-breakpoint-up(lg) {
      color: $secondary;
      display: block;
      font-weight: 600;
    }
  }
}

.go-down {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%) !important;
}
