// Bootstrap
@import "partials/variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
// @import "~bootstrap/scss/utilities";

// Custom
@import "partials/mixins";

// Base
@import "partials/base";

// Components
@import "partials/components/nav";
@import "partials/components/footer";
@import "partials/components/tabs";
@import "partials/components/accordion";
@import "partials/components/breadcrumbs";
@import "partials/components/ul-list";

// Homepage
@import "partials/homepage/hero";
@import "partials/homepage/our_offer";
@import "partials/homepage/opinions";
@import "partials/homepage/map";

// Services pages
@import "partials/services/hero-service";

// Contact page
@import "partials/contact/hero-contact";

// Gallery page
@import "partials/gallery/gallery";

// TEAM
@import "partials/team/team";
