.opinions {
  display: grid;
  align-items: center;
  grid-auto-flow: row;
  grid-template-areas:
    "header"
    "content"
    "navigation";
  gap: 2rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: start;
    grid-template-columns: 330px auto;
    grid-template-areas:
      "header content"
      "navigation content";
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 400px auto;
  }

  &-header {
    grid-area: header;
  }

  &-content {
    grid-area: content;
    min-width: 0;
  }

  &-navigation {
    grid-area: navigation;
  }

  &-author {
    display: block;

    @include media-breakpoint-up(md) {
      position: relative;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 2px;
      height: 1.5rem;
      background: $gradient;
      right: 50%;
      transform: translateX(50%);

      @include media-breakpoint-up(md) {
        top: 50%;
        transform: translateY(-50%);
        right: calc(100% + 0.5rem);
        width: 1rem;
        height: 2px;
      }

      @include media-breakpoint-up(lg) {
        right: calc(100% + 1rem);
        width: 2rem;
      }
    }
  }
}
