body {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400;
  color: $text-color;
}

h6,
h5,
h4,
h3,
h2,
h1 {
  color: $header-color;
  font-weight: $font-weight-bolder;
  font-family: "Montserrat", sans-serif;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.zindex-fix {
  position: relative;
  z-index: 1;
}

.img-cover {
  position: absolute;
  object-fit: cover;
  object-position: center;
  inset: 0;
  width: 100%;
  height: 100%;
}

.img-cover-top {
  @extend .img-cover;
  object-position: top;
}

.rounded-img {
  border-radius: 0 40px 0 80px;
  max-width: 140px;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

a {
  color: $dark;
  &:hover {
    color: $secondary;
    background: $gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.active {
    color: $secondary;
    background: $gradient;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.flex-fill {
  min-width: 0;
}

// CUSTOM
.roboto {
  font-family: "Roboto", sans-serif !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif !important;
}

.btn {
  border-radius: 500px;
  background-color: transparent;
  border-color: transparent;
}

.btn-gradient {
  background-image: linear-gradient(to right, $gradient-color-1, $gradient-color-2, $gradient-color-1, $gradient-color-2) !important;
  transition: all 0.4s ease-in-out;
  background-size: 300% 100% !important;
  background-clip: initial !important;
  -webkit-text-fill-color: initial !important;
  font-weight: 500;
  padding: 0.625rem 1.5rem;

  color: #fff !important;
  border-color: transparent !important;

  &:hover {
    background-position: 100% 0;
  }
}

.text-gradient {
  color: $secondary;
  background: $gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient-x {
  background-color: $dark;
  background: $gradient;
}

.bg-gradient-y {
  background-color: $dark;
  background: $gradient-y;
}

.bg-overlay {
  position: relative;

  &::after {
    content: "";
    inset: 0;
    z-index: 0;
    position: absolute;
    background: $gradient;
    opacity: 25%;
  }
}

.d-lg-contents {
  @include media-breakpoint-up(lg) {
    display: contents;
  }
}

.divider {
  display: block;
  margin: 20px 0 20px 42px;
  height: 45px;
  width: 2px;
  background: $gradient-y;

  @include media-breakpoint-up(lg) {
    &-lg-x {
      height: 2px;
      width: 45px;
      background: $gradient;
    }
  }
}

.divider-box {
  @extend .divider;
  margin-top: -25px;
  z-index: 1;
  position: relative;
}

.page-content {
  font-size: 0.9375rem;
  color: $text-color;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400;
}
