.template-team {

    .col-image .img-team:nth-child(1),
    .col-image .img-team:nth-child(2) {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 1/1;
    }

    .col-image .img-team {
        width: 50%;
        max-height: 320px;
        object-fit: cover;
        img {
            aspect-ratio: 1/1;
            max-width: 100%;
            max-height: 320px;
        }
    }

    .img-team {
        position: relative;
        &:after {
            content: "";
            z-index: 0;
            opacity: 0.25;
            background: linear-gradient(90deg, #6640d1, #36a0e6);
            inset: 0;
            position: absolute;
        }
    }


    @media screen and (max-width: 776px) {

        .col-image .img-team:nth-child(1),
        .col-image .img-team:nth-child(2) {
            width: 100%;
        }

        .col-image .img-team {
            width: 100%;
        }
    }

    @media screen and (max-width: 750px) {

        .col-image .img-team:nth-child(1),
        .col-image .img-team:nth-child(2) {
            width: 50%;
        }

        .col-image .img-team {
            width: 25%;
        }
    }
}

.team-name-decoration {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: -5.2rem;
        top: calc(100% + 6px);
        width: 50px;
        height: 2px;
        background: $gradient;
    }
}