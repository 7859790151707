footer {
  .nav-main a {
    color: #fff !important;
    font-weight: 600;
  }

  .c-fs-small {
    font-size: 0.75rem !important;
  }
}
