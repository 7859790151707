.nav-tabs {
  @extend .list-unstyled;
  display: flex;
}

.tab {
  @extend .btn;
  padding: 0.75rem 1rem;
  background-color: $light;
  border-radius: 0;
  margin: 0;

  font-size: 0.75rem;
  color: $text-muted;
  margin-bottom: 0;
  font-weight: 500;
  font-family: "Roboto", sans-serif;

  &.active,
  &:hover {
    color: $info;
  }

  &-text {
    display: none;
    font-size: 0.75rem;
    color: $text-muted;

    &.active {
      display: block;
    }
  }
}
