.c-breadcrumb {
  @extend .list-unstyled;
  display: flex;
  color: #fff;
  font-weight: 500;

  a:hover {
    background: initial !important;
    background-clip: initial !important;
    -webkit-text-fill-color: initial !important;
    color: #fff !important;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  li + li {
    margin-left: 2.5rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 1rem;
      height: 2.5px;
      top: 50%;
      left: -1.75rem;
      transform: translateY(-50%);
      background: $gradient;
    }
  }
}

.c-breadcrumb.text-dark a:hover {
  color: $dark !important;
}
